import { createContext } from 'react';

type ContextType = {
    line: number;
    setLine: (line: number) => void;
};

//export const Context = createContext<ContextType | null>(null);
export const Context = createContext({} as ContextType);

