import React from 'react';

import { Typography, Box, Grid2 as Grid, Container} from '@mui/material';


import Train from './components/Train';
import { OccupancyState } from './components/icons/Occupancy';

import { useEffect, useState, useContext } from 'react';

import { Context } from './Context';
import { relative } from 'path';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme();

type Dataset = {
    line: {
        number: number,
        route: string
    }
    live: {
        station: string,
        time: string,
        delay: number,
        occupancy: number,
        passengers: number,
        cars: OccupancyState[][]
    }
    prognoses: {
        station: string,
        time: string,
        delay: number,
        occupancy: number,
        passengers: number,
        cars: OccupancyState[][]
    }[]
}




const LiveView = () => {
    const [dataset, setDataset] = useState<null | Dataset>(null);
    const hideScroll = useMediaQuery(theme.breakpoints.up('lg'));
    

    const { line } = useContext(Context);

    const updateDataset = () => {
        fetch(`${process.env.REACT_APP_API_URL}/data/${line}`)
            .then((response) => response.json())
            .then((data) => setDataset(data))
            .catch((error) => console.error(error));
    }


    useEffect(() => {
        let ws = new WebSocket(`${process.env.REACT_APP_WS_URL}`);

        ws.onmessage = (event) => {
            const notification = JSON.parse(event.data);
            if (notification.notification === 'NEW_DATA') {
                if (`${notification.route}` === `${line}`) {
                    updateDataset();
                }
            }
        };

        updateDataset();

        return () => ws.close();
    }, [line]);

    return (
        <Container sx={{ height: 894, width: 710, boxShadow: 3, borderRadius: 2,  margin: "0 auto", overflowY: 'hidden' }}>
          
          
          <Grid container spacing={2} sx={{ paddingTop: '34px' }}>
            <Grid size={2}>
                <Typography fontSize={18.5} fontWeight={'bold'} align="left">Live:</Typography>
            </Grid>
            <Grid size={3} offset={1}>
                <Typography fontSize={18.5} align="right">
                    <span style={{ fontWeight: 'bold' }}>Zug</span>: {dataset?.line?.number || ''}
                </Typography>
            </Grid>
            <Grid size="grow" >
                <Typography fontSize={18.5} align="right">{dataset?.line?.route}</Typography>
            </Grid>
          </Grid>

          <Box border="2px solid #dddddd" borderRadius={'3px'} padding={'0px 10px 5px 6px'} marginTop="34px">
             <Train 
                sx={{ paddingTop: "0px" }} 
                cars={dataset?.live?.cars || []} 
                station={dataset?.live?.station || ''} 
                passengers={dataset?.live?.passengers || 0} 
                time={dataset?.live?.time || ''} 
                delay={dataset?.live?.delay || 0} 
                occupancy={dataset?.live?.occupancy || 0}
                 />
          </Box>

          <Typography fontSize={18.5} fontWeight={'bold'} align="left" marginTop="34px">Prognosen</Typography>
        <Box style={{ overflow: 'hidden', position: 'relative',  }}>
          <Box style={{ overflow: 'auto', height: '665px', width: hideScroll ? '665px' : '100%' }}>
          {dataset?.prognoses.map((data, index) => (
                <Box padding={'0px 5px 0px 5px'} >
                    <Train sx={{  }}  station={data.station} time={data.time} delay={data.delay} occupancy={data.occupancy} passengers={data.passengers} cars={data.cars} />
                </Box>
                )
              )
            }
            
            {dataset && dataset?.prognoses.length < 7 && (
                <Box padding={'0px 5px 0px 5px'} style={{ width: '100%', height: (100 +  100 * (7 - dataset?.prognoses.length)) }} />
            )}
            <Box padding={'0px 5px 0px 5px'} style={{ width: '100%', height: 100}} />
          </Box>
        </Box>

        </Container>
    );
};

export default LiveView;