import React, { useState, useEffect } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Drawer, Toolbar, Typography, Box, Grid2 as Grid } from '@mui/material';
import Topbar from './Topbar';
import { Context } from './Context';
import Sidebar from './components/Sidebar';
import useMediaQuery from '@mui/material/useMediaQuery';

const theme = createTheme();

const drawerWidth = 240;

const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [line, setLine] = useState(7624);
    
    const openSidebar = useMediaQuery(theme.breakpoints.up('lg'));

    const [forceOpen, setForceOpen] = useState(false);

    const updateRoutes = () => {
        fetch(`${process.env.REACT_APP_API_URL}/lines`)
            .then((response) => response.json())
            .then((data) => setLine(data[0]?.lines[0].line || 7624))
            .catch((error) => console.error(error));
    }

    useEffect(() => {
        updateRoutes();
    }, []);


    return (
        <ThemeProvider theme={theme}>
            <Context.Provider value={{ line, setLine }}>
            <CssBaseline />
            <Topbar menuState={forceOpen} openAction={setForceOpen} />
            
            <Box sx={{ display: 'flex' }}>

                <Drawer
                    variant="temporary"
                    
                    open = {openSidebar || forceOpen}
                    hideBackdrop = {true}
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box', bgcolor: '#18364a' },
                    }}
                    onClick={() => setForceOpen(false)}
                >
                    <Sidebar />
                </Drawer>
                <Box
                    component="main"
                    sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3, mt: 8 }}
                >
                    <Toolbar />
                    {children}
                </Box>
            </Box>
            </Context.Provider>
        </ThemeProvider>
    );
};

export default Layout;