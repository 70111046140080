import React from 'react';
import { AppBar, Toolbar, Typography, Box, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

type TopbarProps = {
    menuState: boolean;
    openAction: (open: boolean) => void;
};

const Topbar = (props: TopbarProps) => {
    return (
        <AppBar position="fixed" sx={{ backgroundColor: '#192338' }}>
            <IconButton
                size="large"
                edge="end"
                color="inherit"
                aria-label="menu"
                sx={{ position: 'absolute', left: 0, top: '7px', zIndex: 1, display: { lg: 'none' } }}
                
                onClick={() => props.openAction(!props.menuState)}
            >
                <MenuIcon />
            </IconButton>
            <Toolbar sx={{ justifyContent: 'center' }}>
                <Box
                    component="img"
                    sx={{
                        height: 40,
                        marginRight: 2,
                    }}
                    alt="Bahn Stuttgart Logo"
                    src="/logo.png"
                />
            </Toolbar>
        </AppBar>
    );
};

export default Topbar;