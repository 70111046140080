import React, { useState, useContext, useEffect } from 'react';


import {  Link, Typography, Box, Grid2 as Grid } from '@mui/material';

import { Context } from '../Context';
import { TrainPlate } from './TrainPlate';

interface SidebarProps {
    // Add any props you need for the sidebar here
}


type Route = {
    route: string,
    lines: {
        line: number,
        active: boolean,
        lastUpdate: string
    }[]
}



const mockRoutes = [
    {
        route: 'S1',
        lines: [
            { line: 7624, active: true },
            { line: 7625, active: false },
        ]
    },
];




const Sidebar: React.FC<SidebarProps> = () => {

    const [routes, setRoutes] = useState<[] | Route[]>([]);

    const updateRoutes = () => {
        fetch(`${process.env.REACT_APP_API_URL}/lines`)
            .then((response) => response.json())
            .then((data) => setRoutes(data))
            .catch((error) => console.error(error));
    }

    useEffect(() => {
        let ws = new WebSocket(`${process.env.REACT_APP_WS_URL}`);

        ws.onmessage = (event) => {
            // const notification = JSON.parse(event.data);
            // if (notification.notification === 'ROUTES_CHANGED') {
            //     updateRoutes();
            // }
            updateRoutes();
        };

        updateRoutes();

        return () => ws.close();
    }, []);

    const { line, setLine } = useContext(Context);

    return (
        <React.Fragment>
            <Box sx={{ overflow: 'auto', margin: '80px 4px 17px', color: '#98a1aa' }}>
                <Typography sx={{ fontSize: '12px', marginLeft: '25px' }} noWrap>
                  LIVE TRAINS:
                </Typography>
            </Box>

            <Grid container spacing={0} sx={{ margin: '0px 0px 0px 0px' }}>
                {routes.map((route: any, routeIndex: any) => (
                    <Grid key={routeIndex} size={4}>
                        
                        <Typography sx={{ fontSize: '12px', marginLeft: '15px', color: '#5d6679' }} noWrap>
                            {route.route}
                        </Typography>

                        {route.lines.map((train: any, trainIndex: any) => {
                            
                            return (
                                <TrainPlate key={trainIndex} train={train} line={line} setLine={setLine} />
                            );
                        })}
                    </Grid>
                ))}        
            </Grid>
        </React.Fragment>
    );
};

export default Sidebar;