import React, { useState, useContext, useEffect } from 'react';


import {  Link, Typography, Box, Grid2 as Grid } from '@mui/material';



type Route = {
    route: string,
    lines: {
        line: number,
        active: boolean,
        lastUpdate: string
    }[]
}


type TrainPlateProps = {
    train: {
        line: number,
        active: boolean,
        lastUpdate: string
    },
    line: number,
    setLine: (line: number) => void
};


export const TrainPlate = ({ train, line, setLine }: TrainPlateProps) => {
    const blinkTime = 30000;
    let baseColor = train.active ? '#ffff54' : '#ffffff';
    
    const [color, setColor] = useState(baseColor);
    const [bold, setBold] = useState('normal');

    const eventTime = new Date(train.lastUpdate);
    const currentTime = new Date();
    const diff = currentTime.getTime() - eventTime.getTime();                            


    useEffect(() => {
        if (diff < blinkTime) {
            setColor('#FFA500');
            setBold('bold');
         }

        setTimeout(() => {
            setColor(baseColor);
            setBold('normal');
        }, blinkTime - diff);

        return () => {
            
        }
    }, [train.lastUpdate]);


    return (
        <Typography textAlign={'center'} fontWeight={bold} sx={{ fontSize: '12px', padding: '13px', color: '#ffffff', ...( `${train.line}` === `${line}` ? { background: "#163244" } : {} ) }} noWrap>
            <Link color={color} underline="none" href="#" onClick={() => { setLine(train.line) }}>{train.line}</Link>
        </Typography>
    )
};
